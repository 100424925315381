import { useDark, useDateFormat, useNow } from '@vueuse/core'
import {
  format,
  formatDistanceToNowStrict,
  formatDuration,
  intervalToDuration,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns'
import { Configuration } from '~/api/auth'
import { getToken, useUser } from '~/composables/auth/use-customer-info'

export function isDark(): boolean {
  let isDark: boolean
  if (isEvening()) {
    isDark = true
  }
  else {
    const user = useUser()
    if (user.value)
      isDark = user.value!.isDark === true
    else
      isDark = useDark().value
  }
  // console.debug(`isDark = ${isDark}`)
  return isDark
}

export async function withAxiosToken() {
  const token = await getToken()

  if (!token) {
    console.debug(`No token: guest request...`)
    return new Configuration()
  }
  return new Configuration({ accessToken: token!.split(/[, ]+/).pop() })
}

// ⏱️ T I M E ⏱️ //
export function timeOnly(date: string = new Date().toString()): string {
  return format(new Date(date), 'HH:mm')
}
export function timeFormat(date: string = new Date().toString()): string {
  return format(new Date(date), 'E, MMM d')
}

export function todayYYYYmmDD(): string {
  return format(new Date(), 'yyyy-MM-dd')
}

export function todayYYYYmmDD2(): string {
  return useDateFormat(useNow(), 'yyyy-MM-dd').value
}

export function timeAgo(date: string): string {
  return formatDistanceToNowStrict(new Date(date), { addSuffix: true })
}

export function timeLeftTill(endDate: string): string {
  const duration = intervalToDuration({ start: new Date(), end: new Date(endDate) })
  return formatDuration(duration, { format: ['years', 'months', 'days', 'hours', 'minutes'] })
}

export function date(dateString: string): string {
  const date: Date = parseISO(dateString)
  if (isToday(date))
    return 'Today' // todo: <-- locale

  else if (isYesterday(date))
    return 'Yesterday' // todo: <-- locale

  else
    return format(date, 'MMMM d, yyyy')
}

function isEvening(): boolean {
  const eveningStart = 18
  const now: number = new Date().getHours()
  return now > eveningStart
}

/// /////////////// DOM scrolling 🛟
export function scrollToBottom(elementId: string = 'scroll-target'): void {
  nextTick().then(() => {
    const scrollBox: HTMLElement | null = document.getElementById(elementId)
    if (!scrollBox)
      console.warn(`📍 No ${elementId} element to scroll!`)
    scrollBox?.lastElementChild?.scrollIntoView(/* { behavior: 'smooth' } */)
  })
}
